/*
    Notification plugin
    Usage:
        import Notifications from '...'
        Vue.use(Notifications)

    Then, include the following element in your main component

        <notification-center></notification-center>

    API

        vm.$notifications.show(text, type)  => type: success|error|info|progress
        vm.$notifications.showSuccess(text)
        vm.$notifications.showError(text)
        vm.$notifications.showInfo(text)
        vm.$notifications.showProgress(text, current, total) => There can only be one progress at a time

*/
import Notifications from './notifications.vue'

var Plugin = {
    install(vue, options) {

        if (this.installed) {
            return;
        }
        this.installed = true;

        // Attach global component
        vue.component('notificationCenter', Notifications);

        // Communication bus
        this.event = new vue({});

        // Attach to Vue instance
        vue.prototype.$notifications = Plugin;

    },
    show(text, type) { this.event.$emit('newnote', { text, type }); },
    showSuccess(text) { this.show(text, 'success'); },
    showError(text) { this.show(text, 'error'); },
    showInfo(text) { this.show(text, 'info'); },
    showProgress(text, current, total) {
        var s = this;
        s.event.$emit('showprogress', { text, current, total });
        if (current && current == total) {
            setTimeout(() => {
                s.hideProgress();
            }, 300);
        }
    },
    hideProgress() { this.event.$emit('hideprogress'); }
};

export default Plugin;
