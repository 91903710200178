import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import css from "./assets/app.css";
import apiService from "./services/apiService.js";

Vue.config.productionTip = false;

// Set environment defaults
if(Vue.config.devtools){
    // In development mode
    apiService.setBaseURL("http://localhost:8888");
}else{
    // In production mode
    apiService.setBaseURL("https://ogindex.org/api");
};

// Notifications
import Notifications from './plugins/notifications/notifications.js';
Vue.use(Notifications);

// Components
import Mandatory from '@/components/utils/mandatory.vue';
Vue.component("Mandatory", Mandatory);

import LimitEntry from "@/components/utils/limitinput.vue";
Vue.component("LimitEntry", LimitEntry);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
