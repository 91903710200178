import apiService  from "./apiService.js";

export default {

    getCountries(){
        let uri=`/countries`;
        return apiService.getAPI(uri);
    },
    getCounties(country){
        let uri=`/countries/${country}/counties`;
        return apiService.getAPI(uri);
    },
    getVisits(){
        let uri="/visits";
        return apiService.getAPI(uri);
    },
    getTotalRecords(){
        let uri="/records/count";
        return apiService.getAPI(uri);
    },
    doSearch(search){
        let uri=`/records/${search.country}/${search.county}/${search.town}`,
            params={}
        ;

        if(search.year_from && search.year_from > 0){
            params.from=search.year_from;
        }
        if(search.year_to && search.year_to > 0){
            params.to=search.year_to;
        }
        params.page=search.page;
        if(search.births){
            params.births=1;
        }
        if(search.marriages){
            params.marriages=1;
        }
        if(search.deaths){
            params.deaths=1;
        }
        if(search.burials){
            params.burials=1;
        }

        params.order=search.order;
        params.dir=search.dir;
        params.size=search.page_size;

        // console.log(params)
        return apiService.getAPI(uri, params);
    },
    registerSearchCounter(){
        let uri="/visits";
        apiService.putAPI(uri);        
    }
}