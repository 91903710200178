<template>
  <div id="app">
    <main-header></main-header>
    <main class="flex-grow">
        <router-view />
    </main>
    <main-footer></main-footer>
    <notification-center></notification-center>
  </div>
</template>

<script>
import mainHeader from "@/components/headers/main-header.vue";
import mainFooter from "@/components/footers/main-footer.vue";

export default {
    components:{
        mainHeader,
        mainFooter
    }    
}
</script>
<style scoped>
#app{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

</style>
