import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('../views/Donate.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/donation-thankyou',
    name: 'donationthankyou',
    component: () => import('../views/DonationThankYou.vue')
  },
  {
      path:"*",
      name: "notfound",
      component: ()=>import("../views/NotFound.vue")
  }
]

const router = new VueRouter({
    mode: 'history',
  routes
})

export default router
