<template>
  <strong class="w3-text-red" title="Mandatory"> *</strong>
</template>

<script>
export default {

}
</script>

<style>

</style>