<template>
    <div class="semi-padded gray-gradient">

        <div class="page-center side-padded footer-grid w3-border-bottom ">
            <div class="endorsed">
                <p class="flex-container">
                    <img
                        src="/img/fhfederation_logo_only.png"
                        alt="Family History Federation logo"
                    />

                    <span>
                        Endorsed by the Federation of Family History Societies
                    </span>
                </p>
            </div>

            <p class="align-center">
                Know of good sites to add?
                <br />
                <router-link :to="{ name: 'contact' }">Contact us</router-link>
            </p>

            <p class="align-center">
                Do you want to support the site?
                <br />
                <router-link :to="{ name: 'donate' }">Donate</router-link>
            </p>
        </div>
        <div class="padded align-center">

            OGI is a free, non-profit service provided by Tim Manners (Copyright 2014-2023). 
            Latest site update by <a href="https://pdgaraguso.com" target="_blank">P.D.Garaguso</a> (2021).
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
img {
    
    max-height: 2.6rem;
    margin-right: 1rem;
}


.endorsed {
    grid-row: 1/3;
}
</style>