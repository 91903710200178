import axios from "axios";

//const API_URL="https://ogindex.org/api";
// const API_URL="http://localhost:8888";


class API{
    constructor() {
        let s = this;
        // Make this a Singleton
        if (API._instance) {
            return API._instance;
        } else {
            API._instance = this;
        }

        // Set defaults for website
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        
        return this;
    }

    setBaseURL(url){
        this.API_URL=url;
    }

    // Returns a promise for the response data
    getAPI(url, params={}){
        let s=this;

        return new Promise((resolve, reject)=>{
            axios
                .get(s.API_URL + url,{params})
                .then(res=>{
                    if(res.status>=200 && res.status <300){
                        resolve(res.data)
                    }else{
                        reject();
                    }
                }, err=>{
                    reject(err);
                })
                .catch(err=>{
                    reject();
                })
        });
    }// end get


    // Returns a promise for the response data
    postAPI(url, payload={}){
        let s=this;
        return new Promise((resolve, reject)=>{
            axios
                .post(s.API_URL + url, payload)
                .then(res=>{
                    if(res.status>=200 && res.status <300){
                        resolve(res.data)
                    }else{
                        reject();
                    }
                }, err=>{
                    reject(err);
                })
                .catch(err=>{
                    reject();
                })
        });
    }// end post

    putAPI(url, payload={}){
        let s=this;
        return new Promise((resolve, reject)=>{
            axios
                .put(s.API_URL + url, payload)
                .then(res=>{
                    if(res.status>=200 && res.status <300){
                        resolve(res.data)
                    }else{
                        reject();
                    }
                }, err=>{
                    reject(err);
                })
                .catch(err=>{
                    reject();
                })
        });
    }// end post

}

export default new API();