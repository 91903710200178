<template>
<div>
    <textarea v-model="text" class="w3-input w3-border no-outline" @input="checkLength()" :placeholder="placeholder"></textarea>
    <div class="w3-right-align" :class="{'w3-text-red bold':text.length==limit, 'w3-text-dark-gray': text.length<limit}">
        <span>{{text.length}} / {{limit}}</span>
    </div>
</div>  
</template>

<script>
/** A drop-in replacement for a text-area. It limits the input to a default 500 characters
    and displays a counter.

    use with v-model

    Props:

        limit
        placeholder

 */
export default {
    props:{
        value:{
            type: String,
            default:""
        },
        limit:{
            type: Number,
            default: 300
        },
        placeholder:{
            type: String,
            default:""
        }
    },
    data(){return {
        text:""
    }},
    mounted(){
        if(this.value){
            this.text=this.value;
        }
    },
    methods:{
        checkLength(){
            let s=this;
            if(s.text.length<=s.limit){
                s.$emit("input", s.text);
            }else{
                s.text=s.text.substring(0, s.limit);
            }
        }
    }
}
</script>

<style scoped>

</style>