import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      search:{
          country:"",
          county:"",
          town:"",
          year_from:null,
          year_to:null,
          births:true,
          marriages:true,
          deaths:true,
          burials:true,
          order_by: "title",

          page:1,
          page_size: 20,
          order:"info",
          dir:"ASC"
      },
      countries:[],
      counties:[],
      results:{},
      first_search: true
  },
  mutations: {
      setCountries(state, values){
          state.countries=values;
      },
      setCounties(state, values){
          state.counties=values;
      },
      setResults(state, value){
          state.results=value;
      },
      resetSearch(state){
        //   state.search.country="";
          state.search.county="";
          state.search.town="";
          state.search.year_from=null;
          state.search.year_to=null;
          state.search.births=true;
          state.search.marriages=true;
          state.search.deaths=true;
          state.search.burials=true;
          Vue.set(state, "results", {});
      }
  },
  actions: {
  },
  modules: {
  }
})
