<template>
    <div class="notification-container">

        <!-- Success, info and error -->
        <div class="notification" v-for="note in notes" :key="note.id" :class="note.type" @click="hideNote(note.id)">
            <div class="notification-title">
                <strong v-if="note.type=='success'"><i class="fas fa-fw fa-check-square"></i> Success</strong>
                <strong v-if="note.type=='error'"><i class="fas fa-fw fa-exclamation-triangle"></i> Error</strong>
                <strong v-if="note.type=='info'"><i class="fas fa-fw fa-info-circle"></i> Information</strong>
            </div>
            <div class="notification-text" v-html="note.text"></div>
        </div>

        <!-- Progress note -->
        <div class="notification progress" @click="hideProgress()" v-show="show_progress">
            <div class="notification-title">
                <strong><i class="fas fa-fw fa-spin fa-circle-notch"></i> Working...</strong>
            </div>
            <div class="notification-text">
                <span v-html="progress.text"></span>
                <div v-show="progress.total">{{progress.current}}/{{progress.total}}</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            notes: [],
            show_progress: false,
            progress: {
                text: "Progress default text",
                current: 0,
                total: 100
            }
        }
    },
    created() {
        this.$notifications.event.$on('newnote', this.showNote);
        this.$notifications.event.$on('showprogress', this.showProgress);
        this.$notifications.event.$on('hideprogress', this.hideProgress);
    },
    computed: {
    },
    methods: {
        showNote(note) {
            let s=this,
                id = this.getId()
                ;

            s.notes.push({ id, text: note.text, type: note.type });
            setTimeout(() => {
                s.hideNote(id);
            }, 4000);

        },
        hideNote(id) {
            let s=this,
                i = s.notes.findIndex((note) => { return note.id == id });
            if (i > -1) {
                s.notes.splice(i, 1);
            }
        },
        showProgress(note) {
            this.show_progress = true;
            this.progress.text = note.text;
            this.progress.current = note.current;
            this.progress.total = note.total;
        },
        hideProgress() {
            this.show_progress = false;
        },
        getId(){
            return new Date().getTime()+Math.random();
        }
    }
}
</script>

<style scoped>
.notification-container {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0.5rem;
    height: auto;
    width: auto;
}

.notification {
    color: #fff;
	animation: slideInDown 100ms ease-out;
}

.notification-title{
    background-color: rgba(0,0,0,0.2);
    margin: 0;
    padding: 0.4rem 0.6rem;
}

.notification-text{
    min-width: 20rem;
    padding: 0.4rem 0.6rem 0.4rem 2rem;
    word-wrap:normal;
}

.success {
    background-color: green;
}

.error {
    background-color: red;
}

.info {
    background-color: cornflowerblue;
}

.progress {
    background-color: purple;
}



@keyframes slideInDown {
	0%{transform: translateY(-200%);}
	100%{transform: translateY(0);}
}
</style>
