<template>
<nav class="no-select side-padded gray-background">
    <div class="page-center flex-container flex-justify">
    <div>
        <router-link :to="{name:'home'}" class="clickable bold" custom v-slot="{navigate}">
            <h4 @click="navigate">Online Genealogical Index</h4>                    
        </router-link>
    </div>
    <div class="menu-icon">
        <span class="clickable w3-hover-blue" @click="showSideMenu()">
            <i class="fas fa-fw fa-bars"></i> Menu
        </span>
    </div>
    <div class="main-menu" @click="hideSideMenu()" :class="{'drawer':show_drawer}">

        <router-link :to="{name:'home'}"    class="w3-hover-blue" active-class="bold" exact>Home</router-link>
        <router-link :to="{name:'search'}"  class="w3-hover-blue" active-class="bold">Search</router-link>
        <router-link :to="{name:'contact'}" class="w3-hover-blue" active-class="bold">Contact</router-link>
        <router-link :to="{name:'donate'}"  class="w3-hover-blue" active-class="bold">Donate</router-link>
        <a class="w3-hover-blue" href="https://ogindex.blogspot.com/" target="_blank">
            News <i class="fas fa-fw fa-external-link-square-alt"></i>
        </a>
        <router-link :to="{name:'about'}"   class="w3-hover-blue" active-class="bold">About</router-link>
        <router-link :to="{name:'help'}"    class="w3-hover-blue" active-class="bold">Help</router-link>

    </div>
    </div>
</nav>
</template>

<script>
/**
    Navigation with media queries and drawer animation
 */
export default {
    data(){return {
        show_drawer:false
    }},
    methods:{
        showSideMenu(){
            this.show_drawer=true;
        },
        hideSideMenu(){
            this.show_drawer=false;
        }
    }

}
</script>

<style scoped>
nav{
    position: sticky;
    top:0;
    z-index: 10000;
}

nav a,
nav span{
    text-decoration: none;
    padding: 1rem;
    display: inline-block;
    transition: all 200ms ease-in-out;
    color: #000;
}

.menu-icon{
    display:none;
}

.main-menu{
    transition: all 300ms ease-in-out;
}

/** Media queries */

@media  screen and (max-width : 800px){
    .menu-icon{
        display: block !important;
    }

    .main-menu{
        display: block;
        position: fixed;
        top:0;
        right:0;
        height: 100vh;
        transform: translateX(100%);
    }

    .main-menu.drawer{
        display: block;
        /* background-color: #223; */
        background-color: #fff;
        /* color: #eee; */
        box-shadow: -5px 0 5px rgba(0,0,0,0.2);
        transform: translateX(0);
        width: 100%;
        max-width: 60vw;
    }

    .main-menu a{
        display: block;
        min-width: 10rem;
    }
}

</style>