import axios from "axios";
import marked from "marked";

export default {
    getDocumentList(){
        return this.getJson("helpdocuments.json")
    },
    getDocument(filename){
        let uri=`/data/${filename}`;
        return this.get(uri);
    },
    get(uri){
        return new Promise((resolve, reject)=>{
            axios.get(uri).then(response=>{
                resolve(marked(response.data));
            }, ()=>{
                resolve("")
            });
        });
    },
    getJson(filename){
        let uri=`/data/${filename}`;
        return new Promise((resolve, reject)=>{
            axios.get(uri).then(response=>{
                resolve(response.data);
            }, ()=>{
                reject()
            });
        });
    }
}