<template>
<div>
    <div class="hero-image" :style="{'background-image': `url(${background.image_src})` }">
        <div class="page-center hero-container">

            <div class="teaser-box w3-center-align w3-animate-opacity w3-animate-top">  

                <h1 class="semi-padded ">Online Genealogical Index</h1>
                <strong class="text-white-shadow semi-padded">A simple, free and efficient way to find genealogical data online</strong>
                
                <div class="flex-container padded" style="justify-content: center;">
                    <div class="flex-container">
                        <country-list @change="clearSearch()" @keydown.enter="searchCountry()"></country-list>
                        <button :disabled="country==''" class="w3-button w3-blue flex-no-shrink" @click="searchCountry()">
                            Search now!
                        </button>                
                    </div>
                </div>

                <div  class="align-center side-padded w3-margin-bottom">
                    <strong class="text-white-shadow padded">
                        {{localNumber(visits)}} free research sessions served <br>
                        with {{localNumber(total_records)}} record sets available.
                    </strong>
                </div>
            </div>

        </div>
        <span class="image-title">
            {{background.title}}
        </span>
        <span class="image-credit">
            Photo by <a :href="background.attribution_url" target="_blank">{{background.attribution}}</a>
        </span>
    </div>

    <!-- Instructions -->
    <div class="page-center padded w3-large align-center">

        <div class="side-padded">
            <p>
                Don't know where to go to find the site that holds your ancestor's records? 
                <br>
                With only a place and an approximate year, we will suggest the best sites to continue your research.
            </p>        
        </div>
    </div>

    <!-- More features -->
    <div>
    
        <div class="page-center ">

            <!-- <h2 class="align-center">And best of all: free forever!</h2> -->

            <div class="flex-container flex-space-evenly flex-wrap">
            
                <div class="align-center feature-card flex-no-shrink">
                    <h1 class="w3-text-blue"><i class="fas fa-fw fa-thumbs-up fa-2x"></i></h1>
                    <p>Easy to use, free, and efficient! All the links are 100% collected and verified by humans and for humans</p>
                </div>
                <div class="align-center feature-card flex-no-shrink">
                    <h1 class="w3-text-green"><i class="fas fa-fw fa-hands-helping fa-2x"></i></h1>
                    <p>Recognized in the community and endorsed by the Federation of Family History Societies</p>
                </div>
                <div class="align-center feature-card flex-no-shrink">
                    <h1 class="w3-text-deep-orange"><i class="fas fa-fw fa-search-dollar fa-2x"></i></h1>
                    <p>Easily identify free and paid sites that contain the information you are looking for</p>
                </div>

            </div>
        </div>
    </div>

    <!-- Sponsors -->
    <div class="page-center padded" v-if="sponsors.length">

        <h2 class="align-center">Trusted and recommended by these organizations</h2>

        <div class="flex-container flex-align-center flex-space-evenly flex-wrap flex-responsive semi-padded with-gap">
            <a :href="spo.link" target="_blank" v-for="(spo,i) in sponsors" :key="i" class="sponsor-card">
                <img :src="spo.image_src">                
                <!-- <h5>{{spo.title}}</h5> -->
            </a>
        </div>

    </div>

</div>
</template>

<script>
import documentService from "@/services/documentLoader.js";
import searchService from "@/services/searchService.js";
import CountryList from "@/components/lists/countryList.vue";

export default {
    name: "Home",
    data(){return{
        sponsors:[],
        backgrounds:[],
        visits: 0,
        total_records:0
    }},
    computed:{
        background(){
            let s=this;
            if(s.backgrounds.length){
                return s.backgrounds.find(item=>{
                    return item.country==s.country;
                })

            }else{
                return {
                    "country":"",
                    "image_src":"/img/hugo-sousa-1Z7QDZqT2QQ-unsplash.jpg",
                    "title":"",
                    "attribution":"Hugo Sousa",
                    "attribution_url":"https://unsplash.com/@hmiguelsousa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                }
            }
        },
        countries(){
            return this.$store.state.countries;
        },
        country:{
            get(){
                return this.$store.state.search.country;
            },
            set(value){
                this.$store.state.search.country=value;
            }
        }
    },
    created(){
        this.loadImages();
        this.loadSponsors();
        this.loadVisits();
        this.loadTotalRecords();
    },
    mounted(){
        // let s=this;
        // if(s.$store.state.search.country){
        //     s.country=s.$store.state.search.country;
        // }
    },
    methods:{
        searchCountry(){
            let s=this;
            // s.$store.state.search.country=s.country;
            s.$router.push({name:"search"})
        },
        loadSponsors(){
            let s=this;
            documentService.getJson("sponsors.json").then(data=>{
                s.$set(s, "sponsors", data);
            },()=>{})
        },
        loadImages(){
            let s=this;
            documentService.getJson("backpictures.json").then(data=>{
                s.$set(s, "backgrounds", data);
            },()=>{})
        },
        loadVisits(){
            let s=this;
            searchService.getVisits().then(data=>{
                s.visits=data.num_visits;
            },()=>{
                s.visits=0;
            });            
        },
        loadTotalRecords(){
            let s=this;
            searchService.getTotalRecords().then(data=>{
                s.total_records=data.total;
            },()=>{
                s.total_records=0;
            });            
        },
        localNumber(num){
            return num.toLocaleString();
        },
        clearSearch(){
            this.$store.commit("resetSearch");
        }
    },
    components: {
        CountryList
    },
};
</script>

<style scoped>
.hero-image{
    background:url('/img/hugo-sousa-1Z7QDZqT2QQ-unsplash.jpg');
    /* background:linear-gradient( transparent 70%, white),url('/img/hugo-sousa-1Z7QDZqT2QQ-unsplash.jpg'); */
    /* display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; */
    position: relative;
    min-height: 45vh;
    background-attachment:scroll;
    background-size: cover;
    background-position: center center;
}
.image-title,
.image-credit{
    display: inline-block;
    position: absolute;
    right:0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 2px 4px;
    border-radius: 10px;
    color: #fff;
    text-shadow: 1px 1px black;
}

.image-title{
    right: auto;
    left:0;
}

.hero-container{
    height: 100%;
    display: flex;
    justify-content: center;
}

.teaser-box{
    color: #fff;
    /* background-color: rgba(120,120,120,0.1); */
    /* background: linear-gradient(rgba(0,0,0,0.5) 0%, rgb(255, 255, 255, 0.2) 50% ,rgba(255,255,255,0.1) 100%); */
    /* backdrop-filter: blur(10px); */
    /* border-radius: 0.8rem; */
    
    text-align: center;
    width:100%;
    max-width: 44rem;
    margin: 5rem auto;
    overflow: hidden;
}

.teaser-box h1{
    margin-top:0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255,255,255,0.6);
    font-weight: 900;
    text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000,
                  0 4px 8px #333;
    letter-spacing: 1px;
}


.teaser-box a, .teaser-box button{
    transition: all 200ms ease-in-out;
}

.feature-card{
    width: 30%;
    padding: 2rem;
}

.text-white-shadow{
    /* text-shadow: 0 0 4px white, 0 1px 6px white, 0 -1px 6px white; */
    font-size: 1.1em;
    /* text-shadow: -1px 1px 2px #fff,
				  1px 1px 2px #fff,
				  1px -1px 0 #fff,
				  -1px -1px 0 #fff,
                  0 4px 8px #ffffff66; */
    color: #fff;
    text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000,
                  0 0 4px #ffffff;
    letter-spacing: 1px;
}

.sponsor-card{
    width: 32%;
    padding: 1.8rem;
    text-align: center;
}

.sponsor-card img{
    max-height: 6rem;
    max-width: 100%;
}

.sponsor-card:hover {
    box-shadow: 0 0 1rem #ccc;
}

.dark-shadow{
    background-color: rgba(0,0,0,0.3);
}

@media  screen and (max-width : 800px){

    .teaser-box{
        backdrop-filter: blur(8px);
        max-width: 100%;
        margin: 2rem 1rem;
    }
    .feature-card{
        width: 100%;
    }
}
</style>
