<template>
<select v-model="$store.state.search.country" @change="changeCountry()"
        class="w3-input w3-white w3-border" placeholder="Select country">
    <option value="">Select the country ({{countries.length}} available)...</option>
    <option v-for="item in countries" :value="item.country" :key="item.country">{{item.country}}</option>
</select>
</template>

<script>
/**
    Events:
        change()
 */
import searchService from "@/services/searchService.js";
export default {
    data(){return{}},
    computed:{
        countries(){
            return this.$store.state.countries;
        }
    },
    created(){
        this.loadCountries();
    },
    methods:{
        loadCountries(){
            let s=this;
            if(s.$store.state.countries.length==0){
                searchService.getCountries().then(data=>{
                    s.$store.commit("setCountries", data);
                },()=>{
                    s.$set(s, "countries", []);
                });
            }
        },
        changeCountry(){
            this.$store.commit("resetSearch");
            this.$emit("change");
        }
    }
}
</script>

<style>

</style>